@font-face {
  font-family: "Bree Serif";
  src: url("./assets/fonts/BreeSerif-Regular.ttf"); }

.font-Bree {
  font-family: "Bree Serif"; }

@font-face {
  font-family: "Optimus Princeps";
  src: url("./assets/fonts/OptimusPrincepsSemiBold.ttf");
  font-style: bold; }

.font-optimus {
  font-family: "Optimus Princeps"; }

@font-face {
  font-family: "Keys";
  src: url("./assets/fonts/Where-My-Keys.otf");
  font-weight: 300; }

.font-keys {
  font-family: "Keys"; }

@font-face {
  font-family: "Sinkin Sans";
  src: url("./assets/fonts/SinkinSans-300Light.ttf");
  font-weight: 300; }

.font-Sinkin {
  font-family: "Sinkin Sans"; }

a {
  color: inherit;
  text-decoration: none; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

input,
textarea {
  border: 1px solid #555;
  border-radius: 0.2rem; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1rem 0; }
  h1.-normal,
  h2.-normal,
  h3.-normal,
  h4.-normal,
  h5.-normal,
  h6.-normal {
    font-weight: normal; }
  h1.-xs,
  h2.-xs,
  h3.-xs,
  h4.-xs,
  h5.-xs,
  h6.-xs {
    font-size: 0.25em; }
  h1.-s,
  h2.-s,
  h3.-s,
  h4.-s,
  h5.-s,
  h6.-s {
    font-size: 0.5em; }
  h1.-md,
  h2.-md,
  h3.-md,
  h4.-md,
  h5.-md,
  h6.-md {
    font-size: 1em; }
  h1.-lg,
  h2.-lg,
  h3.-lg,
  h4.-lg,
  h5.-lg,
  h6.-lg {
    font-size: 3em; }
    @media (max-width: 320px) {
      h1.-lg,
      h2.-lg,
      h3.-lg,
      h4.-lg,
      h5.-lg,
      h6.-lg {
        font-size: 2.5em; } }
    @media (min-width: 768px) {
      h1.-lg,
      h2.-lg,
      h3.-lg,
      h4.-lg,
      h5.-lg,
      h6.-lg {
        font-size: 4em; } }

body {
  font-family: "Sinkin Sans";
  color: #385758; }
  body .color-black {
    color: black; }

html {
  overflow: scroll;
  overflow-x: hidden; }

::-webkit-scrollbar {
  width: 0px; }

::-webkit-scrollbar-thumb {
  background: #ff0000; }

.App {
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: all 0.5s;
  text-shadow: 0 0 6px #fff; }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px; }

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white; }

.App-intro {
  font-size: large; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.container {
  position: relative;
  width: 100vw;
  transition: opacity 0.3s ease-in; }

.section {
  width: 100%;
  min-height: 100vh;
  text-align: center;
  display: flex;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 2rem 0; }
  .section article:not(.no-bg) {
    background-color: rgba(0, 0, 0, 0.8);
    color: #eaeaea;
    text-shadow: none; }

.centered-article {
  margin: auto;
  padding: 1rem; }

.full-width {
  width: 100%;
  max-width: 1024px; }

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: white; }

#firebase-ui-auth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background: #0f5167; }
