@keyframes jumpAnimation {
  form {
    top: 0;
    box-shadow: none; }
  to {
    top: -0.3rem;
    box-shadow: 0 2px 5px #000; } }

.skills {
  /* display: flex;
  //flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column; */ }
  .skills .logo-container {
    border-radius: 0.5rem;
    padding: 0.5rem;
    overflow: hidden;
    margin: 1.5rem 1rem 0.5rem 1rem;
    position: relative;
    top: -0.3rem;
    box-shadow: 0 2px 5px #000; }
