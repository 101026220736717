.timeLine {
  display: flex;
  flex-direction: column; }
  .timeLine .point {
    display: flex;
    position: relative;
    align-items: top;
    margin-bottom: 1rem;
    text-align: left;
    border-radius: 1rem;
    padding: 1rem;
    line-height: 1.5;
    /* border: 1px solid #888;
        background: rgba(56, 87, 88, 0.7);
        color: white; */
    text-shadow: none; }
    .timeLine .point .leftSide {
      text-align: center;
      flex-basis: 20%;
      margin-bottom: 1rem; }
      .timeLine .point .leftSide .image {
        width: 5rem;
        height: 5rem;
        margin: auto;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        box-shadow: 0 0 0.5rem #000;
        margin-bottom: 0.5rem; }
    .timeLine .point .rightSide {
      margin-left: 1rem;
      flex-basis: 80%; }
  @media (max-width: 768px) {
    .timeLine .point {
      flex-direction: column;
      border: 1px solid #ddd;
      border-width: 1px 0 1px 0; }
      .timeLine .point .rightSide {
        margin-left: 0;
        text-align: center; } }
  .timeLine .point .description {
    font-size: medium; }
  .timeLine .point .read-more-state, .timeLine .point .read-more-trigger {
    display: none; }
  @media (max-width: 768px) {
    .timeLine .point .description {
      opacity: 0;
      max-height: 0;
      transition: .25s ease; }
    .timeLine .point .read-more-state:checked ~ .rightSide .description {
      opacity: 1;
      max-height: 999em; }
    .timeLine .point .read-more-state ~ .read-more-trigger:before {
      content: 'Show more'; }
    .timeLine .point .read-more-state:checked ~ .read-more-trigger:before {
      content: 'Show less'; }
    .timeLine .point .read-more-trigger {
      cursor: pointer;
      display: block;
      margin: auto;
      padding: 0.5em;
      color: #ddd;
      font-size: .9em;
      line-height: 2;
      border: 1px solid #ddd;
      border-radius: .25em; } }
