@keyframes hideElement {
  0% {
    height: auto;
    padding: 0.25rem;
    opacity: 1; }
  100% {
    height: 0;
    padding: 0;
    overflow: hidden;
    opacity: 0; } }

@keyframes showElement {
  0% {
    height: 0;
    padding: 0;
    overflow: hidden;
    opacity: 0; }
  100% {
    height: auto;
    padding: 0.25rem;
    opacity: 1; } }

.menu {
  padding: 0.5rem;
  box-shadow: 0px -0px 6px 0px #000; }
  .menu .list {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .menu .item {
    padding: 0.25rem; }
    .menu .item:not(.last) a {
      position: relative; }
      .menu .item:not(.last) a:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: #222;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s; }
      .menu .item:not(.last) a:hover:before {
        visibility: visible;
        transform: scaleX(1); }
  .menu .item.last .fa {
    transition: all 0.5s; }
  .menu.show .item:not(.last) {
    animation: showElement 0.5s forwards ease-in-out; }
  .menu.show .item.last .fa {
    transform: rotate(180deg); }
  .menu.hide .item:not(.last) {
    animation: hideElement 0.5s forwards ease-in-out; }
  .menu.hide .item.last .fa {
    transform: rotate(0deg); }
